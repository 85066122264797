import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kxl-skip-progress',
  templateUrl: './skip-progress.component.html',
  styleUrls: ['./skip-progress.component.scss']
})
export class SkipProgressComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
