import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'kxl-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  entries$;

  constructor(private data: DataService) {}

  ngOnInit() {
    this.entries$ = this.data.getHistory().pipe(tap(console.log));
  }

  weekInfo(weekId) {
    const weekDate = moment(weekId, 'YYYYWW');
    return `${weekDate.format('DD. MMM')} - ${weekDate
      .add(5, 'day')
      .format('DD. MMMM')}`;
  }
}
