export class Pixel {
  pos = { x: 0, y: 0 };
  alpha;
  scale;
  velocity;
  context;
  height;
  width;

  constructor(height, width, context) {
    this.context = context;
    this.height = height;
    this.width = width;
    this.init();
  }

  init() {
    this.pos.x = Math.random() * this.width;
    this.pos.y = this.height + Math.random() * 100;
    this.alpha = 0.1 + Math.random() * 0.3;
    this.scale = 0.3 + Math.random() * 0.4;
    this.velocity = Math.random();
  }

  draw() {
    if (this.alpha <= 0) {
      this.init();
    }
    this.pos.y -= this.velocity;
    this.alpha -= 0.0005;
    /*this.context.beginPath();
    this.context.arc(this.pos.x, this.pos.y, this.scale * 10, 0, 2 * Math.PI, false);
    this.context.fillStyle = 'rgba(0,255,255,' + this.alpha + ')';
    this.context.fill();*/
    this.context.fillStyle = 'rgba(255,255,255,' + this.alpha + ')';
    this.context.fillRect(
      this.pos.x,
      this.pos.y,
      this.scale * 10,
      this.scale * 10
    );
  }
}
