import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { DataService } from './shared/data.service';
import { AuthService } from './shared/auth.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'kxl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isHandset$: Observable<boolean>;
  studios$;
  authenticated$;
  currentStudio$;

  constructor(
    private san: DomSanitizer,
    private registry: MatIconRegistry,
    private breakpointObserver: BreakpointObserver,
    private data: DataService,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private update: SwUpdate
  ) {
  }

  ngOnInit(): void {
    this.update.available.subscribe(() => {
      this.snackBar
        .open('A new version of this app is available!', 'Activate now')
        .afterDismissed()
        .subscribe(dismiss => {
          if (dismiss.dismissedByAction) {
            this.update.activateUpdate().then(() => document.location.reload());
          }
        });
    });
    this.authenticated$ = this.auth.isAuthenticated;
    this.studios$ = this.data.getEnabledStudios();
    this.currentStudio$ = this.data.currentStudio;
    this.registry.addSvgIconSet(
      this.san.bypassSecurityTrustResourceUrl('/assets/icons/set.svg')
    );
    this.isHandset$ = this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map(result => result.matches));
  }

  logout() {
    this.auth.logout();
  }

  login() {
    this.auth.login();
  }
}
