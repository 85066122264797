import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatSliderModule,
  MatSnackBarModule,
  MatToolbarModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { LoginProgressComponent } from './components/login-progress/login-progress.component';
import { SkipProgressComponent } from './components/skip-progress/skip-progress.component';

const matModules = [
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatListModule,
  MatCardModule,
  MatInputModule,
  MatBadgeModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatMenuModule,
  MatSidenavModule,
  MatSliderModule,
  MatExpansionModule,
  MatSnackBarModule,
  MatDialogModule,
  MatGridListModule,
  MatProgressSpinnerModule
];

const throughPorts = [FlexLayoutModule, ReactiveFormsModule, LayoutModule];

@NgModule({
  imports: [CommonModule, matModules, throughPorts],
  declarations: [LoginProgressComponent, SkipProgressComponent],
  entryComponents: [LoginProgressComponent, SkipProgressComponent],
  providers: [],
  exports: [matModules, throughPorts]
})
export class SharedModule {}
