import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { first } from 'rxjs/operators';

@Component({
  selector: 'kxl-login-progress',
  templateUrl: './login-progress.component.html',
  styleUrls: ['./login-progress.component.scss']
})
export class LoginProgressComponent implements OnInit {

  loginDone = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.data.pipe(first()).subscribe(() => {
      this.loginDone = true;
    });
  }
}
