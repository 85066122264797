import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private studio = new BehaviorSubject<string>('munich');
  private studioConfig = this.studio.pipe(
    switchMap(studio => this.getStudioConfig(studio)),
    map(settings => {
      return { ...settings, studio: this.studio.value };
    })
  );

  constructor(
    private functions: AngularFireFunctions,
    private db: AngularFirestore
  ) {
  }

  get currentStudio() {
    return this.studio;
  }

  private static addIdToEntry(change) {
    return change.map(action => {
      const entry = action.payload.doc.data();
      return { ...entry, id: action.payload.doc.id };
    });
  }

  setLocation(studio) {
    this.studio.next(studio);
  }

  getHistory() {
    return this.studioConfig.pipe(
      switchMap(config =>
        this.db
          .collection('records', ref =>
            ref.orderBy('weekId', 'desc').where('studio', '==', config.studio)
          )
          .snapshotChanges()
          .pipe(map(DataService.addIdToEntry))
      )
    );
  }

  getUser(email) {
    return this.db
      .collection<{ isAdmin?: boolean }>('kaisers', ref =>
        ref.where('email', '==', email)
      )
      .valueChanges();
  }

  skipKaiser() {
    return this.functions.httpsCallable('skipKaiser');
  }

  getKaisersByName() {
    return this.studioConfig.pipe(
      switchMap(config =>
        this.db
          .collection('kaisers', ref =>
            ref.orderBy('name', 'asc').where('studio', '==', config.studio)
          )
          .snapshotChanges()
          .pipe(map(DataService.addIdToEntry))
      )
    );
  }

  getNextWeeks(weekCount: number) {
    return this.studioConfig.pipe(
      switchMap(config =>
        this.db
          .collection('kaisers', ref =>
            ref
              .where('active', '==', true)
              .where('studio', '==', config.studio)
              .orderBy('score', 'asc')
              .limit(config.teamSize * weekCount)
          )
          .snapshotChanges()
          .pipe(
            map(DataService.addIdToEntry),
            map(w =>
              w.reduce(function(result, value, index, array) {
                if (index % config.teamSize === 0) {
                  result.push(array.slice(index, index + config.teamSize));
                }
                return result;
              }, [])
            )
          )
      )
    );
  }

  updateActiveStatus(kaiser: string, active: boolean) {
    return this.db
      .collection('kaisers')
      .doc(kaiser)
      .update({ active });
  }

  getStudios() {
    return this.db
      .collection('settings')
      .doc<{ studios: string[] }>('data')
      .valueChanges()
      .pipe(map(data => data.studios));
  }

  getStudiosWithConfig() {
    return combineLatest(
      this.db
        .collection('studio-settings')
        .snapshotChanges()
        .pipe(map(DataService.addIdToEntry)),
      this.getStudios()
    ).pipe(
      map(([config, studios]) =>
        studios.map(studio => {
          const studioConfig = config.find(c => c.id === studio);
          if (studioConfig) {
            return { ...studioConfig, name: studio };
          }
          return { name: studio, enabled: false };
        })
      )
    );
  }

  getEnabledStudios() {
    return this.db
      .collection('studio-settings', ref => ref.where('enabled', '==', true))
      .snapshotChanges()
      .pipe(map(studios => studios.map(studio => studio.payload.doc.id)));
  }

  updateStudio(studio, value) {
    return this.db
      .collection('studio-settings')
      .doc(studio)
      .update(value);
  }

  getCurrentConfig() {
    return this.studioConfig.pipe(
      switchMap(config =>
        this.db
          .collection('studio-settings')
          .doc<{ teamSize: number; enabled: boolean }>(config.studio)
          .valueChanges()
      )
    );
  }

  getStudioConfig(studio) {
    return this.db
      .collection('studio-settings')
      .doc<{ teamSize: number; enabled: boolean }>(studio)
      .valueChanges();
  }
}
