import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { MatCheckboxChange, MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'kxl-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  config$;
  employees$;
  sizeCtrl = new FormControl(null, [Validators.required]);
  authenticated$;
  admin$;

  constructor(
    private data: DataService,
    private snackBar: MatSnackBar,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.config$ = this.data.getCurrentConfig();
    this.config$
      .pipe(first())
      .subscribe(config => this.sizeCtrl.setValue(config.teamSize));
    this.employees$ = this.data.getKaisersByName();
    this.authenticated$ = this.auth.isAuthenticated;
    this.admin$ = this.auth.isAdmin;
    this.admin$.subscribe(auth => {
      if (auth) {
        this.sizeCtrl.enable();
      } else {
        this.sizeCtrl.disable();
      }
    });
  }

  toggleKaiser(ev: MatCheckboxChange) {
    this.data
      .updateActiveStatus(ev.source.value, ev.checked)
      .then(() => this.snackBar.open('Saved!', null, { duration: 2000 }));
  }

  saveSettings() {
    this.data
      .updateStudio(this.data.currentStudio.value, {
        teamSize: this.sizeCtrl.value
      })
      .then(() => this.snackBar.open('Saved!', null, { duration: 2000 }));
  }

  login() {
    this.auth.login();
  }
}
