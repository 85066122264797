import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { DataService } from '../../shared/data.service';
import { filter, first, map, startWith, tap } from 'rxjs/operators';
import { MatDialog, MatSnackBar } from '@angular/material';
import { PresentComponent } from '../present/present.component';
import { Overlay } from '@angular/cdk/overlay';
import { AuthService } from '../../shared/auth.service';
import { SkipProgressComponent } from '../../shared/components/skip-progress/skip-progress.component';

@Component({
  selector: 'kxl-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.scss']
})
export class UpcomingComponent implements OnInit {
  teams$: Observable<Array<Array<{}>>>;
  currentWeekId = parseInt(moment().format('YYYYWW'), 10);
  currentTeam$;
  matches$;
  teamSize$: Observable<number>;
  admin$;
  largeFlex$ = new BehaviorSubject(60);
  smallFlex$ = new BehaviorSubject(90);

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private auth: AuthService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    if (localStorage.getItem('presentMode') === 'enabled') {
      setTimeout(() => this.present());
    }
    this.admin$ = this.auth.isAdmin;
    this.teams$ = this.dataService.getNextWeeks(5);
    this.currentTeam$ = this.teams$.pipe(map(teams => teams[0]));
    this.matches$ = this.teams$.pipe(map(teams => teams.slice(1)));
    this.teamSize$ = this.dataService.getCurrentConfig().pipe(
      filter(config => !!config),
      map(config => config.teamSize),
      startWith(2)
    );
    this.teamSize$.subscribe(size => {
      if (size === 1) {
        this.largeFlex$.next(30);
        this.smallFlex$.next(45);
      } else {
        this.largeFlex$.next(60);
        this.smallFlex$.next(90);
      }
    });
    // this.present();
  }

  weekInfo(offset) {
    const weekDate = moment(this.currentWeekId, 'YYYYWW').add(offset, 'w');
    return `${weekDate.format('DD. MMM')} - ${weekDate
      .add(5, 'day')
      .format('DD. MMMM')}`;
  }

  present() {
    this.dialog.open(PresentComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'borderlesss',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
  }

  async skipKaiser(kaiser, updateCalendar = true, teamIndex = -1) {
    this.dialog.open(SkipProgressComponent);
    const teamSize = await this.teamSize$.pipe(first()).toPromise();
    const offset = (teamIndex + 1) * teamSize;
    this.dataService.skipKaiser()({
      id: kaiser.id,
      updateCalendar, offset
    }).pipe(tap(() => this.dialog.closeAll())).subscribe(({ data }) => {
      if (!data.success) {
        console.log(data);
        this.snackBar.open(data.message, null, { duration: 3000 });
      }
    }, err => {
      console.error(err);
    });
  }
}
