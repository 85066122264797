import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import * as moment from 'moment';
import { DataService } from '../../shared/data.service';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
import { Pixel } from './pixel';
import { SwUpdate } from '@angular/service-worker';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'kxl-present',
  templateUrl: './present.component.html',
  styleUrls: ['./present.component.scss'],
  animations: [
    trigger('cardChange', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate(
          '300ms 250ms cubic-bezier(0.0, 0.0, 0.2, 1)',
          style({ opacity: '1' })
        )
      ]),
      transition(':leave', [
        animate(
          '300ms cubic-bezier(0.4, 0.0, 1, 1)',
          style({ opacity: '0' })
        )
      ])
    ])
  ]
})
export class PresentComponent implements OnInit, OnDestroy {
  teams$;
  displayTeam$;
  @ViewChild('canvas')
  canvas;
  @ViewChild('teamStrip')
  teamStrip;
  @ViewChild('backgroundDiv')
  backgroundDiv;
  currentSlide = new BehaviorSubject<string>('team');
  weekDisplay = new BehaviorSubject('On duty this week');
  context;
  circles = [];
  currentWeekId = parseInt(moment().format('YYYYWW'), 10);
  componentDies$ = new Subject();

  constructor(
    private dataService: DataService,
    private dialog: MatDialogRef<PresentComponent>,
    private update: SwUpdate
  ) {
  }

  ngOnInit() {
    localStorage.setItem('presentMode', 'enabled');
    interval(30000)
      .pipe(takeUntil(this.componentDies$))
      .subscribe(() => {
        this.update.checkForUpdate().then();
      });
    interval(20000)
      .pipe(takeUntil(this.componentDies$))
      .subscribe(() => {
        if (this.currentSlide.value === 'team') {
          this.currentSlide.next('tasks');
          this.weekDisplay.next('Your tasks');
        } else {
          this.currentSlide.next('team');
          this.weekDisplay.next('On duty this week');
        }
      });
    this.update.available.subscribe(() => {
      this.update.activateUpdate().then(() => document.location.reload());
    });
    this.teams$ = this.dataService.getNextWeeks(4);
    this.displayTeam$ = this.teams$.pipe(map(teams => teams[0]));
    this.canvas.nativeElement.height = this.canvas.nativeElement.clientHeight;
    this.canvas.nativeElement.width = this.canvas.nativeElement.clientWidth;
    this.context = this.canvas.nativeElement.getContext('2d');
    for (let x = 0; x < this.canvas.nativeElement.clientWidth * 0.5; x++) {
      const c = new Pixel(
        this.canvas.nativeElement.clientHeight,
        this.canvas.nativeElement.clientWidth,
        this.context
      );
      this.circles.push(c);
    }
    this.animate();
    /*this.currentSlide.subscribe(slideIndex => {
      if (slideIndex) {
        const weekDate = moment(this.currentWeekId + slideIndex, 'YYYYWW');
        this.weekDisplay.next(
          `${weekDate.format('DD. MMM')} - ${weekDate
            .add(5, 'day')
            .format('DD. MMM')}`
        );
      } else {
        this.weekDisplay.next('Current Week');
      }
    });
    this.currentSlide
      .pipe(switchMap(() => timer(30000).pipe(first())))
      .subscribe(() => {
        this.currentSlide.next(0);
      });*/
  }

  ngOnDestroy(): void {
    this.componentDies$.complete();
    localStorage.removeItem('presentMode');
  }

  close() {
    this.dialog.close();
  }

  private animate() {
    this.context.clearRect(
      0,
      0,
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight
    );
    this.circles.forEach(circle => circle.draw());
    requestAnimationFrame(() => this.animate());
  }
}
