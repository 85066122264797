import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { OfficeLocationGuard } from './shared/guards/office-location.guard';
import { UpcomingComponent } from './office/upcoming/upcoming.component';
import { HistoryComponent } from './office/history/history.component';
import { SettingsComponent } from './office/settings/settings.component';
import { PresentComponent } from './office/present/present.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { isHome: true } },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: ':studio',
    canActivate: [OfficeLocationGuard],
    children: [
      { path: 'upcoming', component: UpcomingComponent },
      { path: 'present', component: PresentComponent },
      { path: 'history', component: HistoryComponent },
      { path: 'settings', component: SettingsComponent },
      { path: '', pathMatch: 'full', redirectTo: 'upcoming' }
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UpcomingComponent,
    HistoryComponent,
    SettingsComponent,
    PresentComponent
  ],
  entryComponents: [PresentComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFirestoreModule.enablePersistence({ experimentalTabSynchronization: true }),
    AngularFireAuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
