import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { AuthService } from '../shared/auth.service';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'kxl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  studios$;
  admin$;

  constructor(private data: DataService, private auth: AuthService) {}

  ngOnInit() {
    this.studios$ = this.data.getStudiosWithConfig();
    this.admin$ = this.auth.isAdmin;
  }

  studioChange(ev: MatCheckboxChange) {
    this.data.updateStudio(ev.source.value, { enabled: ev.checked });
  }

  updateStudio(studio, enabled) {
    if (this.admin$.value) {
      this.data.updateStudio(studio, { enabled });
    }
  }
}
