import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { delay, first, map } from 'rxjs/operators';
import { auth } from 'firebase/app';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from './data.service';
import { MatDialog } from '@angular/material';
import { LoginProgressComponent } from './components/login-progress/login-progress.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAdmin$ = new BehaviorSubject(false);

  constructor(private fireAuth: AngularFireAuth, private data: DataService, private zone: NgZone, private dialog: MatDialog) {
    this.fireAuth.user.subscribe(authUser => {
      if (authUser) {
        this.data
          .getUser(authUser.email)
          .pipe(first())
          .subscribe(user => this.isAdmin$.next(!!user[0].isAdmin));
      }
    });
  }

  get isAuthenticated() {
    return this.fireAuth.user.pipe(map(u => !!u));
  }

  get isAdmin() {
    return this.isAdmin$;
  }

  get user() {
    return this.fireAuth.user;
  }

  public login() {
    const provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
      hd: 'kaiser-x.com'
    });
    const subject$ = new Subject();
    this.fireAuth.auth
      .signInWithPopup(provider).then(data => {
      this.zone.run(() => {
        subject$.next(data);
      });
    });
    this.dialog.open(LoginProgressComponent, { data: subject$, disableClose: true });
    subject$.pipe(delay(2500), first()).subscribe(() => {
      this.dialog.closeAll();
    });
    return subject$;
  }

  public logout() {
    this.fireAuth.auth.signOut().then(() => this.isAdmin$.next(false));
  }
}
